@import url(./styles/animations.css);
@import url(./styles/fonts.css);

body {
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  font-family: Inter, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.page-layout {
  animation: show 1000ms linear 50ms;
  padding-bottom: 24px;
}

.grecaptcha-badge {
  visibility: hidden;
}

main {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 112px);
}

@media print {
  * {
    overflow: visible !important;
    width: auto !important;
    height: auto !important;
    max-height: auto !important;
    page-break-before: always !important;
  }

  header nav,
  footer {
    display: none;
  }

  @page {
    margin: 1.5cm 1.5cm 1.5cm 2cm;
    marks: none;
    orphans: 4;
    widows: 2;
    size: portrait;
  }
}

main {
  height: 100%;
}

html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container {
  width: 100%;
  max-width: 1212px;
  padding-left: 54px;
  padding-right: 54px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
}

button,
input,
textarea {
  font-family: inherit;
}

a {
  text-underline-position: under;
}

@media (hover: none) {
  * {
    cursor: default;
  }
}

.scrollbar > div:nth-child(3) {
  width: 4px !important;
  right: 0 !important;
  border-radius: 4px;
}

.scrollbar > div:nth-child(3) > div {
  border-radius: 4px;
}

.show-content {
  overflow: hidden;
  height: 100%;
  opacity: 1;
  transition: all 200ms ease;
}

.hide-content {
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: all 200ms ease;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popup-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
}

.popup-wrapper .popup-content {
  transition: transform 400ms ease, opacity 400ms ease;
}

.popup-show {
  opacity: 1;
  transition: transform 400ms ease, opacity 400ms ease;
}

.popup-show .popup-content {
  transform: translateX(0);
}

.popup-hide {
  opacity: 0;
}

.popup-hide .popup-content {
  transform: translateX(10px);
}

.popup-hidden {
  opacity: 0;
  display: none;
  z-index: -1;
}

.popup-hidden .popup-content {
  transform: translateX(-10px);
}

@keyframes error-dissapear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error-dissapear {
  animation: error-dissapear 5000ms linear 0s;
}

.image-slider-control-item {
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  background-position: center;
  background-size: cover;
  transition: all 200ms ease;
  opacity: 0.4;
}

.image-slider-control-item:hover {
  cursor: pointer;
}

.image-slider-control-item-current {
  opacity: 1;
}

.image-slider-item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-40px);
  transition: 300ms ease;
  top: 0;
  left: 0;
}

.image-slider-item-active {
  opacity: 1;
  transform: translateX(0);
}

.displayNone {
  display: none;
}

.relocation-input-wrapper {
  width: 100%;
  position: relative;
}

.relocation-input {
  position: relative;
  border: none;
  border-radius: 12px;
  padding: 10px 16px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgb(70, 70, 70);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 25px;
  outline: none;
}

.relocation-input-label {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 156px 24px;
}

@keyframes show-from-right {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes hide-to-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-40px);
  }
}

.show-from-right {
  animation: show-from-right 200ms ease 0s;
}

.hide-to-left {
  animation: hide-to-left 200ms ease 0s;
}