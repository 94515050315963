.switch-enter {
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.switch-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.switch-exit {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.switch-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.filters-content-enter {
    transform: translateX(40px);
    opacity: 0;
    transition: all 200ms ease-in;
}

.filters-content-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 200ms ease-in;
}

.filters-content-exit {
    transform: translateX(0px);
    opacity: 1;
    transition: all 200ms ease-in;
}

.filters-content-exit-active {
    opacity: 0;
    transform: translateX(-40px);
    transition: all 200ms ease-in;
}