@keyframes show-modal-input {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.order-modal-input {
  animation: show-modal-input 400ms ease 0s;
}